import React from "react"
import { graphql } from "gatsby"
import { PageBySlugQuery } from "../generated/graphql"
import idx from "idx"

const PageTemplate = ({ data }: { data: PageBySlugQuery }) => {
  const html = idx(data, (_) => _.markdownRemark.html) || ""

  return <div dangerouslySetInnerHTML={{ __html: html }} />
}

export const query = graphql`
  query PageBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        subtitle
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
      }
    }
  }
`

export default PageTemplate
